//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import '../../storeManagement/common/previewImage'
export default {
  name: "storeContainerDetail",
  data() {
    
    return {
        ossPath:'https://bxn-qy-workspace.oss-cn-hangzhou.aliyuncs.com/Layout/Area/',
        lmnum: "",//布局模块顺序号
        sortf: "",//设计区域编号
        flpkt: "1",//货柜数量
        lmver: "",//布局模块版本
        mandt: "",//客户端
        length: "",//长
        laygr: "",//区域
        sortft:"",
        kunnr: "",//客户编号
        shqno: "",//优化货架数量
        width: "",//宽
        lwhUom: "mm",//计量单位(长/宽/高)
        shqnm: "",//最大SKU数
        height: "",//高
        layvr: "",//设计
        layvrt:"",//设计名称
        preqn: "",//陈列数量
        flpkt1:"1.0",//货柜数带小数
        storeName:"",//门店名称
        storeCode:"",//门店id
        sortfPath:"",
        title:"",
        layoutList:[],
        layoutAreaListFiltered:[],
        isShowLayoutPicker:false,
        layoutAreaList:[],
        isShowLayoutAreaPicker:false,
        layoutAreaSearch:''
//         aa:{

//       "layvr": "Z001",
//       "mandt": "300",
//       "laygr": "Z020",
//       "length": "7.000",
//       "width": "7.000",
//       "height": "7.000",
//       "shqno": "7.000",
//       "shqnm": "7.000",
//       "preqn": "7.000"

//       "lmnum": "000001", //新增不传
//       "sortf": "1000000011",
//       "flpkt": "00000000",
//       "lmver": "0000",
//       "kunnr": "E001Z003",
//       "lwhUom": "mm",


// }
    };
  },
  watch:{
    layoutAreaSearch(val,old){
      this.layoutAreaListFiltered = this.layoutAreaList.filter(item =>{
        return item.sortft.indexOf(val) > -1;
      })
    }
  },
  created() {
    this.getLayoutList()
    console.log(this.$route.params);
    Object.assign(this.$data,this.$route.params)
    if(this.$route.params && this.$route.params.lmnum){
      window.document.title = '编辑门店布局';
      this.title = '编辑门店布局';
    }else{
      window.document.title = '新增门店布局';
      this.title = '新增门店布局';
    }
    if(!this.$route.params.storeCode){
      this.$toast({ message: "门店数据丢失，请重新选择门店", duration: 2000 });
      this.$router.replace({name:"StoreInfo"});
    }
  },
  methods: {
      previewImage: function (url) {
        let arr = this.sortfPath.split(',')
        let urls = []
        arr.map(v=>{
          if(v){
            urls.push(this.ossPath + v )
          }
        })
        if(/Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(navigator.userAgent)){
          window.previewImage.start({
            current: url, // 当前显示图片的http链接
            urls: urls // 需要预览的图片http链接列表
          });
        }else{
          wx.previewImage({
            current: url, // 当前显示图片的http链接
            urls: urls // 需要预览的图片http链接列表
          });
        }
    },
    submit() {
      console.log("onSubmit");
      let that = this;
      let postData = {
        layvr:this.layvr,
        mandt:this.mandt,
        laygr:this.laygr,
        length:this.length,
        width:this.width,
        height:this.height,
        kunnr: this.kunnr || this.storeCode,
        shqno: this.shqno,
        shqnm: this.shqnm,
        preqn: this.preqn,
        sortf: this.sortf,
        flpkt: this.flpkt,
        flpkt1:this.flpkt1,
        //下面参数传默认值
        lmver: "0000",
        lwhUom: "mm",
       
      }
      if(!this.layvr){
          this.$toast({message: '请选择设计', duration: 3000});
          return false;
      }
      if(!this.laygr){
          this.$toast({message: '请选择区域', duration: 3000});
          return false;
      }
      if(!this.flpkt1){
          this.$toast({message: '请输入货柜数量', duration: 3000});
          return false;
      }
      // if(!this.length){
      //     this.$toast({message: '请输入货柜长度', duration: 3000});
      //     return false;
      // }
      // if(!this.width){
      //     this.$toast({message: '请输入货柜宽度', duration: 3000});
      //     return false;
      // }
      // if(!this.height){
      //     this.$toast({message: '请输入货柜高度', duration: 3000});
      //     return false;
      // }
      // if(!this.shqnm){
      //     this.$toast({message: '请输入最大SKU数', duration: 3000});
      //     return false;
      // }
      // if(!this.shqno){
      //     this.$toast({message: '请输入最小SKU数', duration: 3000});
      //     return false;
      // }
      // if(!this.preqn){
      //     this.$toast({message: '请输入陈列数量', duration: 3000});
      //     return false;
      // }
      //有lmnum编辑 没有新增
      if(that.lmnum){ 
        postData.lmnum = that.lmnum
      }
      let url = that.lmnum ? "/store/display/change" : "/store/display/create";
      console.log(url,postData);
      
      this.$toast.loading({
        message: "正在提交中",
        forbidClick: true,
        duration: 0,
      });
      // return
      that.axios.post('/' + this.$ajaxPrefix.consumer + url,postData)
      .then((res) => {
          console.log(res);
           that.$toast.clear();
          if(res.data.data){
            that.$toast({ message: "保存成功", duration: 1000 });
            setTimeout(()=>{
              that.goBack()
            })
          }
        });
    },
    goBack() {
      this.$router.go(-1);
    },
    getLayoutList(storeCode) {
      this.$toast.loading({
        message: "数据获取中",
        forbidClick: true,
        duration: 0,
      });
      const that = this;
      this.storeOption = [];
      this.axios
        .get('/' + this.$ajaxPrefix.consumer +"/store/layout/list",{params:{storeCode}})
        .then((res) => {
          this.$toast.clear();
          if (res && res.data && res.data.data) {
            res.data.data.map(v =>{
              v.text = v.layvrt
              v.value = v.layvrt
            })
            //按代数排序
            that.layoutList = res.data.data.sort((a,b) =>{
              return (a.layvr < b.layvr) ? 1 : -1
            });
          }
        });
    },
    getLayoutAreaList(storeCode) {
      const that = this;
      this.$toast.loading({
        message: "数据获取中",
        forbidClick: true,
        duration: 0,
      });
      this.storeOption = [];
      this.layoutAreaList = [];
      this.layoutAreaListFiltered = [];
      this.axios
        .get('/' + this.$ajaxPrefix.consumer +"/store/layoutArea/list",{params:{storeCode,layvr:that.layvr}})
        .then((res) => {
           this.$toast.clear();
          if (res && res.data) {
              //按代数排序
          //  let arr =  res.data.data.sort((a,b) =>{
          //     return (a.layvr < b.layvr) ? 1 : -1
          //   });
            //按陈列物品类别排序
           let sortArr =  res.data.data.sort((a,b) =>{
              let sortKeys = ['挂','皮带', '皮具','裤子', '衬衫', '鞋', '休闲', '婚庆', '精品', '量体','定制', '正装', '商务']
               return (sortKeys.findIndex(item=>{return a.sortft.indexOf(item) > -1}) < sortKeys.findIndex(item=>{return b.sortft.indexOf(item) > -1})) ? 1 : -1
            });
            // // //按货柜类别排序
            let sortArr2 =  sortArr.sort((a,b) =>{
                return (a.sortft.indexOf('背柜') > -1 && b.sortft.indexOf('中岛') > -1) ? -1 : 1
            });
            that.layoutAreaList = sortArr2;
            that.layoutAreaListFiltered = sortArr2
          }
        });
    },
    showLayoutPicker(){
      if(this.lmnum) {
        this.$toast({message: '设计不可修改', duration: 3000});
        return
      }
      this.isShowLayoutPicker = true;
    },
    changeLayvrt(layvrt){
      console.log(layvrt);
      this.layoutList.map(item =>{
        if(item.layvrt == layvrt){
          this.layvr = item.layvr;
          this.layvrt = item.layvrt;
          this.mandt = item.mandt;
          this.laygr = "";
          this.sortft = "";
          this.sortfPath = ""
          this.getLayoutAreaList()
        }
      })
    },
    confirmLayoutPicker(item) {
      console.log(item);
      if(item.layvr != this.layvr){
        this.layvr = item.layvr;
        this.layvrt = item.layvrt;
        this.mandt = item.mandt;
        this.laygr = "";
        this.sortft = "";
        this.sortfPath = ""
        this.getLayoutAreaList()
      }
      this.isShowLayoutPicker = false;
    },
    closeLayoutPicker(){
      this.isShowLayoutPicker = false;
    },
    showLayoutAreaPicker(){
      if(this.lmnum) {
        this.$toast({message: '区域不可修改', duration: 3000});
        return
      }
      this.isShowLayoutAreaPicker = true;
    },
    changeLayoutArea(item) {
      console.log(item);
      this.laygr = item.laygr;
      this.sortft = item.sortft;
      this.sortf = item.sortf;
      this.sortfPath = item.sortfPath;
      this.isShowLayoutAreaPicker = false;
      this.layoutAreaSearch = '';
    },
    closeLayoutArea(){
      this.isShowLayoutAreaPicker = false;
      this.layoutAreaSearch = '';
    },
  }
 
};
